const data = [
    {
        id: 1,
        image: 1,
        text: 'Harvard',
    },
    {
        id: 2,
        image: 2,
        text: 'Michigan',
    },
    {
        id: 3,
        image: 3,
        text: 'Berkeley',
    },
    {
        id: 4,
        image: 4,
        text: 'Ucla',
    },
    {
        id: 5,
        image: 5,
        text: 'Yale',
    },
    {
        id: 6,
        image: 6,
        text: 'Princeton',
    },
    {
        id: 7,
        image: 7,
        text: 'NYU',
    },
    {
        id: 8,
        image: 8,
        text: 'Chicago',
    },
    {
        id: 9,
        image: 9,
        text: 'Duke',
    },
    {
        id: 10,
        image: 10,
        text: 'Cornell',
    },
    {
        id: 11,
        image: 11,
        text: 'Hopkins',
    },
    {
        id: 12,
        image: 12,
        text: 'Cornegie',
    },
    {
        id: 13,
        image: 13,
        text: 'Brown',
    },
    {
        id: 14,
        image: 14,
        text: 'Boston',
    },
    {
        id: 15,
        image: 15,
        text: 'Florida',
    },
    {
        id: 16,
        image: 16,
        text: 'Tufts',
    },
    {
        id: 17,
        image: 17,
        text: 'Virginia',
    },
    {
        id: 18,
        image: 18,
        text: 'Illinois',
    },
    {
        id: 19,
        image: 19,
        text: 'Washington',
    },
    {
        id: 20,
        image: 20,
        text: 'NotreDame',
    },
    {
        id: 21,
        image: 21,
        text: 'Purdue',
    },
    {
        id: 22,
        image: 22,
        text: 'Indiana',
    },
    {
        id: 23,
        image: 23,
        text: 'Kentucky',
    }
];

export default data;
