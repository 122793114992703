import React from "react";

const PopupDiscountInfo = ({setOpenModal}) => {
    return (
        <div className="wrapper wrapper-popup">
            <div className="discount-popup">
                        <span
                            aria-hidden className="close-popup"
                            onClick={()=>setOpenModal(false)}
                        />
                <h2 className="discount-popup__title">
                    <div>
                        <span><mark>WELCOME2023</mark> was copied to clipboard!</span>
                    </div>
                </h2>

                <p className="discount-popup__text">
                    To redeem the code, press Control + V (or Command + V, if you are on Mac) to paste it into the order form.
                </p>
                <a
                    id="btnPopupDeadline"
                    href={`/offers`}
                    className="button button-primary"
                >
                    start your journey
                </a>
            </div>
        </div>
    )
}

export default PopupDiscountInfo