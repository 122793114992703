import React from "react";

const StatisticExpert = ({className}) => {
    return (
        <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="68" height="68" rx="34" fill="#1F2939"/>
            <path d="M39.8337 44.5V42.1667C39.8337 40.929 39.342 39.742 38.4668 38.8668C37.5917 37.9917 36.4047 37.5 35.167 37.5H25.8337C24.596 37.5 23.409 37.9917 22.5338 38.8668C21.6587 39.742 21.167 40.929 21.167 42.1667V44.5M46.8337 44.5V42.1667C46.8329 41.1327 46.4887 40.1282 45.8553 39.311C45.2218 38.4938 44.3348 37.9102 43.3337 37.6517M38.667 23.6517C39.6708 23.9087 40.5605 24.4925 41.1959 25.311C41.8313 26.1296 42.1761 27.1363 42.1761 28.1725C42.1761 29.2087 41.8313 30.2154 41.1959 31.034C40.5605 31.8525 39.6708 32.4363 38.667 32.6933M35.167 28.1667C35.167 30.744 33.0777 32.8333 30.5003 32.8333C27.923 32.8333 25.8337 30.744 25.8337 28.1667C25.8337 25.5893 27.923 23.5 30.5003 23.5C33.0777 23.5 35.167 25.5893 35.167 28.1667Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default StatisticExpert