import React from "react";

const StatisticSuccessfulProjects = ({className}) => {
    return (
        <svg width="66" height="68" viewBox="0 0 66 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.666992" width="65.0865" height="68" rx="32.5433" fill="#1F2939"/>
            <path d="M30.3177 37.1541L31.4097 41.7976M34.8263 41.7976L35.8126 37.1541M40.5018 33.5633L41.7979 32.903C42.5216 32.5343 43.0724 31.8972 43.3327 31.1278L45.6178 24.3723C45.9934 23.2619 45.57 22.0381 44.5884 21.3972C44.1707 21.1245 43.6825 20.9792 43.1836 20.9792H42.224M23.9064 20.9792H23.2369C22.738 20.9792 22.2499 21.1245 21.8321 21.3972C20.8506 22.0381 20.4271 23.2619 20.8027 24.3723L23.0879 31.1278C23.3481 31.8972 23.899 32.5343 24.6227 32.903L25.5787 33.39M42.8826 47.3778V44.017C42.8826 43.6733 42.604 43.3947 42.2604 43.3947H41.0355V42.2005C41.0355 41.8569 40.757 41.5783 40.4133 41.5783H25.7124C25.3687 41.5783 25.0902 41.8569 25.0902 42.2005V43.3947H23.87C23.5263 43.3947 23.2478 43.6733 23.2478 44.017V47.3778C23.2478 47.7214 23.5263 48 23.87 48H42.2604C42.604 48 42.8826 47.7214 42.8826 47.3778ZM24.5296 20C24.1533 20 23.8631 20.3314 23.9128 20.7044L25.6506 33.7451C25.885 35.1627 27.4092 37.4852 33.0476 37.4852C35.4002 37.4852 39.8905 37.1189 40.4446 33.7451L42.2156 20.706C42.2663 20.3325 41.9759 20 41.599 20H24.5296Z" stroke="white" strokeWidth="2"/>
        </svg>
    )
}

export default StatisticSuccessfulProjects