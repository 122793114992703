import React from "react";

const StatisticWriter = ({className}) => {
    return (
        <svg width="69" height="68" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.333008" width="68" height="68" rx="34" fill="#1F2939"/>
            <path d="M42.2513 45.172V40.4019C42.2513 39.7547 42.2085 39.0759 41.8134 38.5634C40.4366 36.7775 36.5996 35.9706 34.4043 35.9706C30.566 36.0254 28.2531 37.2655 27.1337 38.3586C26.507 38.9706 26.3705 39.8753 26.3705 40.7512V45.172M48.333 34C48.333 41.732 42.065 48 34.333 48C26.601 48 20.333 41.732 20.333 34C20.333 26.268 26.601 20 34.333 20C42.065 20 48.333 26.268 48.333 34ZM34.3332 33.5632C36.6132 33.5632 38.4599 31.7165 38.4599 29.4365C38.4599 27.1565 36.6132 25.3099 34.3332 25.3099C32.0532 25.3099 30.2065 27.1565 30.2065 29.4365C30.2065 31.7165 32.0532 33.5632 34.3332 33.5632Z" stroke="white" strokeWidth="2"/>
        </svg>
    )
}

export default StatisticWriter