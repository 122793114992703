import React, { useState } from "react";
import data from "../content-static/university_extended";

import AliceCarousel from 'react-alice-carousel';


function University() {

    const responsive = {
        0: { items: 1 },
        660: { items: 2 },
        992: { items: 3 },
        1024: { items: 4 }
    }

    const [universities, ] = useState(data);
    const [counter, setCounter] = useState(0)

    const items = universities.map((university, personIndex) => {
        return (
            <span key={personIndex}>
                <div className='infinite-slider-item'>
                    <img
                        src={require(`../images/university_256X160/${university.image}.png`).default}
                        alt={university.text}
                        className=''
                    />
                </div>
            </span>
        )
    })

    return (
        <div className="infinite-slider">
            <h1 className="infinite-slider__title">Have helped thousands of students</h1>
                <div className='infinite-slider-container'>
                    {items}
                    {items}
                    {items}
                    {items}
                </div>
                <div className="infinite-slider-container-mobile">
                    <div className="wrapper infinite-slider-container-mobile-wrapper">
                        <AliceCarousel
                            animationDuration={400}
                            items={items}
                            onSlideChanged={(e) => setCounter(e.item)}
                            activeIndex={counter}
                            infinite
                            controlsStrategy="alternate"
                            responsive={responsive}
                        />
                    </div>
                </div>
        </div>
    );
}

export default University;