import React from "react";
// import LoaderImg from "../images/loader.svg"

const Loader = () => {
    return (
        <div className="loader">
            <svg xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" className="lds-message" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{"background": "#ffffff", "width":"100%", "height":"100%"}}>
                <g transform="translate(20 50)">
                    <circle cx="0" cy="0" r="2" fill="#ff690c" transform="scale(0.284793 0.284793)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.48750000000000004s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.3s" repeatCount="indefinite"/>
                    </circle>
                </g>
                <g transform="translate(40 50)">
                    <circle cx="0" cy="0" r="2" fill="#ff690c" transform="scale(0.0295414 0.0295414)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.325s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.3s" repeatCount="indefinite"/>
                    </circle>
                </g>
                <g transform="translate(60 50)">
                    <circle cx="0" cy="0" r="2" fill="#ff690c" transform="scale(0.0716589 0.0716589)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.1625s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.3s" repeatCount="indefinite"/>
                    </circle>
                </g>
                <g transform="translate(80 50)">
                    <circle cx="0" cy="0" r="2" fill="#ff690c" transform="scale(0.364899 0.364899)">
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.3s" repeatCount="indefinite"/>
                    </circle>
                </g>
            </svg>
        </div>
    )
}

export default Loader
