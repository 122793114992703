import React, { useState } from 'react'
import PopupDiscountInfo from "@components/popup/PopupDiscountInfo";

const IntroPartTwo = () => {
    const [openModal, setOpenModal] = useState(false)
    return (
        <div className="info-second-bg">
            <div className="info-second wrapper">
                <div className="info-second__left">
                    <h1 class="info-second__title">
                        <span>
                            Top Essay <span class="info-second__verified">Writers</span>
                        </span>
                        <span class="info-second__subtitle">on stand-by to help with your assignment</span>
                    </h1>
                    <p className='info-second__desc'>
                        Every day, hundreds of students turn to our academic experts for urgent help with assignments, classes, exams and coursework tasks that are handled by our team with guaranteed quality and on time!
                    </p>
                    <div class="info-second__guaranty-container">
                        <div class="info-second__guaranty">
                            <img
                                src={require('../../images/satisfaction.svg').default}
                                alt="satisfaction"
                            />
                            Coursework & assignments
                        </div>
                        <div class="info-second__guaranty">
                            <img
                                src={require('../../images/satisfaction.svg').default}
                                alt="satisfaction"
                            />
                            Online classes
                        </div>
                        <div class="info-second__guaranty">
                            <img
                                src={require('../../images/satisfaction.svg').default}
                                alt="satisfaction"
                            />
                            Human tutoring
                        </div>
                        <div class="info-second__guaranty">
                            <img
                                src={require('../../images/satisfaction.svg').default}
                                alt="satisfaction"
                            />
                            No ChatGPT/AI
                        </div>
                    </div>
                </div>
                <div
                    class="info-second__img"
                />
            </div>
            
            
            {openModal && <PopupDiscountInfo setOpenModal={setOpenModal}/>}
        </div>
    )
}

export default IntroPartTwo