let utm_medium = null;
let utm_source = null;
let utm_campaign = null;

const getParams = () => {
    if (typeof window !== "undefined") {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        utm_medium = params.utm_medium;
        utm_source = params.utm_source;
        utm_campaign = params.utm_campaign;
    }
}

const setUtmCookie = () => {
    if (typeof document !== "undefined") {
        if (utm_medium) document.cookie = `utm_medium=${utm_medium}; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT; domain=customessaymeister.com`
        if (utm_source) document.cookie = `utm_source=${utm_source}; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT; domain=customessaymeister.com`
        if (utm_campaign) document.cookie = `utm_campaign=${utm_campaign}; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT; domain=customessaymeister.com`
    }
}

const initUtmCookie = () => {
    getParams()
    setUtmCookie()
}

export default initUtmCookie
