import * as React from "react"

import StatisticExpert from "@components/ImagesComponents/StatisticExpert"
import StatisticSuccessfulProjects from "@components/ImagesComponents/StatisticSuccessfulProjects"
import StatisticWriter from "@components/ImagesComponents/StatisticWriter"

const StatisticsSecond = () => {

    return (
        <div className="intro-second-statistics wrapper">
            <div className="intro-second-statistics__card">
                <div className="intro-second-statistics__top">
                    <StatisticSuccessfulProjects className="intro-second-statistics__img" />
                    <div className="intro-second-statistics__info">
                        <h3 className="intro-second-statistics__title">17 years</h3>
                        <p className="intro-second-statistics__description">
                            in academic writing
                        </p>
                    </div>
                </div>
                <div className="intro-second-statistics__bottom">
                    <p className="intro-second-statistics__description">
                    Trusted by thousands of clients since 2006.
                    </p>
                </div>
            </div>
            <div className="intro-second-statistics__card">
                <div className="intro-second-statistics__top">
                    <StatisticWriter className="intro-second-statistics__img" />
                    <div className="intro-second-statistics__info">
                        <h3 className="intro-second-statistics__title">43K clients</h3>
                        <p className="intro-second-statistics__description">
                            from 60 countries
                        </p>
                    </div>
                </div>
                <div className="intro-second-statistics__bottom">
                    <p className="intro-second-statistics__description">
                        Over 250,000 essays written by our team.
                    </p>
                </div>
            </div>
            <div className="intro-second-statistics__card">
                <div className="intro-second-statistics__top">
                    <StatisticExpert className="intro-second-statistics__img" />
                    <div className="intro-second-statistics__info">
                        <h3 className="intro-second-statistics__title">250+ writers</h3>
                        <p className="intro-second-statistics__description">
                            qualified in 75+ disciplines
                        </p>
                    </div>
                </div>
                <div className="intro-second-statistics__bottom">
                    <p className="intro-second-statistics__description">
                        Available to take on your assignment 24/7 no days-off, no holidays.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default StatisticsSecond

