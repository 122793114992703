import React, {useEffect, useState} from "react";
import { Suspense, lazy } from "@uploadcare/client-suspense"
import initUtmCookie from "@helpers/utmCookie.js";
import Layout from "@components/layout"
// import Intro from "@components/mainpage/Intro"
import IntroPartTwo from "@components/mainpage/IntroPartTwo"
// import About from "@components/mainpage/About"
import WhyUs from "@components/mainpage/WhyUs"
// import SampleCarousel from "@components/SampleCarousel"
import BottomBanner from "@components/BottomBanner"
import HowWeWork from "../components/mainpage/HowWeWork"
// import Services from "../components/mainpage/Services"
// import Messenger from "../components/mainpage/MessengerBlock/Messenger"
// import ChooseService from "../components/mainpage/ChooseService"
// import ServiceBanner from "../components/banners/ServiceBanner"
// import Calculator from "@components/Calculator"
import StatisticsSecond from "@components/mainpage/StatisticsSecond"
// import Statistics from "@components/mainpage/Statistics"
import Loader from "@components/Loader"
import Seo from "@components/Seo.js"
import FaqMainPage from "@components/mainpage/FaqMainPage"
import University from "@components/University"
// import About from "@components/mainpage/About"

// import CountdownCounterBanner from "./../components/banners/CountdownСounterBanner"
// import CountdownCounterBannerSecond from "./../components/banners/CountdownСounterBannerSecond"
// import MoneyBackGuaranteeBanner from "./../components/banners/MoneyBackGuaranteeBanner"

//Lazy components
const TheVideo = lazy(() => import('@components/mainpage/TheVideo'));
// const Writers = lazy(() => import('@components/mainpage/Writers'));
const FeedbackCarousel = lazy(() => import('@components/FeedbackCarousel'));

const IndexPage = () => {
    const version = process.env.BUILD_HASH
    const env = process.env.ENV
    const buildTime = process.env.BUILD_DATETIME
    console.log(
        `%c${env}${version} ${buildTime}`,
        'color: #fff; font-style: italic; font-weight: 700; background-color: #5E35B1;padding: 10px 5px; border-radius: 4px;',
    );
    const pageData = {
        title: `Best Essay Writers Online`,
        description: `⭐⭐⭐⭐⭐ Looking for a good writer for your assignment? Our platform can deliver! ⏰ 24/7 Support, 🔓 Full Confidentiality, ✔️ 100% Plagiarism-free!`,
        keywords: `write my paper, write my essay, do my assignment, essay, research paper, do my term paper, dissertation, thesis, book report`,
        micromarking: `
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://www.customessaymeister.com",
                "name": "CustomEssayMeister",
                "images":"https://www.customessaymeister.com/img/og_image.png",
                "description":"Looking for a professional writer for your essay, term paper, book report or dissertation?✍️ Our platform is home to hundreds of experts. ⏰ 24/7 Support, 🔓 Full Confidentiality, ✔️ 100% plagiarism-free!"
            }
        `
    }

    // const writersSliderSetting = {
    //     responsiveViewDesc: 4,
    //     responsiveViewTablet: 3,
    //     responsiveViewTablet2: 2,
    //     responsiveViewMobile: 1,
    //     animationDuration: 400,
    //     autoPlayInterval: 400,
    //     animationType: 'slide',
    //     autoPlay: false,
    //     deskTop: true
    // }

    const [data, setData] = useState(null)

    const fetchData = async () =>  {
        let res = await fetch('https://api.db-ip.com/v2/free/self')
        return res.json()
    }
    initUtmCookie()
    useEffect( ()=> {
        fetchData().then(data => setData(data));
    }, [])

    return (
    <> 
        <Seo {...pageData} />
        <Layout>
            <div className="main-page">
                <IntroPartTwo address={data}/>
                <StatisticsSecond />
                <Suspense fallback={<Loader/>}>
                    <TheVideo image='curveOval'/>
                </Suspense>
                {/* <CountdownCounterBanner /> */}
                {/* <ServiceBanner /> */}
                {/*<About />*/}
                {/* <Statistics/> */}
                {/* <Suspense fallback={<Loader/>}>
                    <Writers writersSliderSetting={writersSliderSetting}>
                        <About/>
                    </Writers>
                </Suspense> */}
                <WhyUs />
                <Suspense fallback={<Loader/>}>
                    <FeedbackCarousel />
                </Suspense>
                <HowWeWork />
                {/* <Calculator /> */}
                {/* <ChooseService /> */}
                {/* <MoneyBackGuaranteeBanner /> */}
                <FaqMainPage />
                <University />
                {/* <SampleCarousel /> */}
                {/* <CountdownCounterBannerSecond /> */}
                {/* <Messenger /> */}
                <BottomBanner />
            </div>
        </Layout>
    </>
    )
}

export default IndexPage