import React, { useState, useEffect, useRef} from "react"
import '../../../src/styles/components/_how-we-work.scss'
const HowWeWork = (props) => {
    const sliderList = [
        {
            step: 0,
            img: '../../images/howWeWork/1.svg',
            title: 'Quick order placement',
            desc: 'Start your success journey with our easy order form. Got a tight deadline? Opt for our express service.'
        },
        {
            step: 1,
            img: '../../images/howWeWork/2.svg',
            title: 'Choose your writer',
            desc: 'Take control of your academic success. Our unique bidding system lets you choose the best writer for your assignment.'
        },
        {
            step: 2,
            img: '../../images/howWeWork/3.svg',
            title: 'Comprehensive research',
            desc: 'Your chosen writer delves into the subject matter, ensuring your paper is well-researched and comprehensive.'
        },
        {
            step: 3,
            img: '../../images/howWeWork/4.svg',
            title: 'Your paper is in progress',
            desc: 'Your personal writer hand-types your paper on a computer. Once finished, the paper must pass our Quality Control.'
        },
        {
            step: 4,
            img: '../../images/howWeWork/5.svg',
            title: 'Plagiarism scan',
            desc: 'We ensure originality with our plagiarism detection software. Our writers don\'t use AI tools, maintaining the personal touch.'
        },
        {
            step: 5,
            img: '../../images/howWeWork/6.svg',
            title: 'Final draft delivery',
            desc: 'Receive your paper, review it, and request free revisions if needed. Your academic success is our priority.'
        }

    ]
    const titles = ['You place an order', 'A qualified writer is assigned', 'Preparation & Research', 'Your paper is in progress', 'Plagiarism scan', 'The final draft arrives']

    const timeoutRef = useRef(null)
    const [activeSlider, setActiveSlider] = useState(null)
    const [mobileState, setMobileState] = useState(false)

    function resetTimeout() {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
    }

    const changeActiveSlide = (index) => {
        let slide = index
        if (index === 6) {
            slide = 0
        } else if (index < 0) {
            slide = 5
        } else {
            slide = index
        }
        setActiveSlider(slide)
        setMobileState(false)
    }

    useEffect(() => {
        setActiveSlider(0)
    }, []);

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>{
            setMobileState(false)
            setActiveSlider((prevIndex) =>
              prevIndex === sliderList.length - 1 ? 0 : prevIndex + 1
            )
          },
          10500
        );
          
        return () => {
            setTimeout(function() {
                setMobileState(true)
            }, 20)
            
          resetTimeout();
        };
    }, [activeSlider, sliderList.length]);

    return (
        <section id="how-it-works" className="hww">
        <div className="hww-wrapper">
            <div className="hww-container">
                <p className="hww-title">Our Process</p>
                <div className="hww-carousel">
                    <div className="hww-carousel__content">

                        <div className="hww-carousel__content-prev-container">
                            <button className="hww-carousel__content-prev-btn" onClick={() => changeActiveSlide(activeSlider - 1)}>&nbsp;</button>
                        </div>
                        <div className="slideshow">
                            <div
                                className="slideshowSlider"
                                style={{ transform: `translate3d(${-activeSlider * 100}%, 0, 0)` }}
                            >
                                {sliderList.map((item, index) => (
                                <div key={item.step} className="slide">
                                        <div className="hww-carousel__container">
                                            <div className="hww-carousel__content-img">
                                                <div className={`hww-carousel__content-img__step hww-carousel__content-img__step-${item.step + 1}`}>
                                                    {item.step + 1}
                                                </div>
                                                <img src={require(`../../images/howWeWork/${item.step + 1}.svg`).default} alt={item.title} />
                                            </div>


                                            <div className="hww-carousel__content-data">
                                                <div className="hww-carousel__content-data__step">Step {item.step + 1}</div>
                                                <div className="hww-carousel__content-data__title">{item.title}</div>
                                                <div className="hww-carousel__content-data__desc">{item.desc}</div>
                                            </div>
                                        </div>
                                </div>
                                ))}
                            </div>
                        </div>
                        <div className="hww-carousel__content-next-container">
                            <button className="hww-carousel__content-next-btn" onClick={() => changeActiveSlide(activeSlider + 1)}>&nbsp;</button>
                        </div>
                        {/* {sliderList.map(item => (
                           
                            <div key={item.step}>
                            {item.step === activeSlider &&
                                <div
                                    className="hww-carousel__container"
                                    
                                >
                                    <div className="hww-carousel__content-img">
                                        <div className={`hww-carousel__content-img__step hww-carousel__content-img__step-${item.step}`}>
                                            {item.step}
                                        </div>
                                        <img src={require(`../../images/howWeWork/${item.step}.svg`).default} alt={item.title} />
                                    </div>


                                    <div className="hww-carousel__content-data">
                                        <div className="hww-carousel__content-data__step">Step {item.step}</div>
                                        <div className="hww-carousel__content-data__title">{item.title}</div>
                                        <div className="hww-carousel__content-data__desc">{item.desc}</div>
                                    </div>
                                </div>
                            }
                            </div>
                        ))} */}
                    </div>

                    <div className="hww-carousel__panel">
                        {titles.map((title, index) => (
                            <React.Fragment key={index}>
                                <div
                                    className="hww-carousel__panel-item"
                                    role="presentation"
                                    onClick={() => changeActiveSlide(index)}
                                    onKeydown={() => changeActiveSlide(index)}
                                >
                                    <figure className="hww-carousel__panel-item__chart" data-percent="100">
                                        <figcaption className="hww-carousel__panel-item__chart-num">{index + 1}</figcaption>
                                        <svg width="90" height="90">
                                            <circle className="inside" cx="150" cy="40" r="35" transform="rotate(-90, 95, 95)"></circle>
                                            <circle className={`outer ${activeSlider === index ? 'outer-active' : ''}`} cx="150" cy="40" r="35" transform="rotate(-90, 95, 95)"></circle>
                                        </svg>
                                    </figure>
                                    <div
                                        className={`${index % 2 === 0 ? 'hww-carousel__panel-item__top-pointer' : 'hww-carousel__panel-item__bottom-pointer'}
                                            ${activeSlider === index ? 'hww-carousel__panel-item__top-pointer__active' : ''}
                                            ${activeSlider > index ? 'hww-carousel__panel-item__top-pointer__late' : ''}
                                        `}
                                    >
                                        <span className={`${index % 2 === 0 ? 'hww-carousel__panel-item__top-pointer__title' : 'hww-carousel__panel-item__bottom-pointer__title'}`}>
                                            {title}
                                        </span>
                                    </div>
                                </div>

                                <div className="hww-carousel__panel-step">
                                    <div className={`hww-carousel__panel-step__left ${activeSlider >= index ? 'hww-carousel__panel-step__left-active' : ''}`} />
                                    <div className={`hww-carousel__panel-step__right ${activeSlider >= index + 1 ? 'hww-carousel__panel-step__right-active' : ''}`} />
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="hww-carousel__panel-mobile">
                        <div className="hww-carousel__panel-step">
                            <div className={`hww-carousel__panel-step__left ${activeSlider + 1 > 1 ? 'hww-carousel__panel-step__right-active' : ''}`}/>
                            <div className="hww-carousel__panel-step__right hww-carousel__panel-step__right-active" />
                        </div>

                        <div className="hww-carousel__panel-item">
                            <figure className="hww-carousel__panel-item__chart" data-percent="100">
                                <figcaption className="hww-carousel__panel-item__chart-num"><b>{activeSlider + 1} </b> / 6</figcaption>
                                <svg width="90" height="90">
                                            <circle className="inside" cx="150" cy="40" r="35" transform="rotate(-90, 95, 95)"></circle>
                                            <circle className={`outer ${mobileState? 'outer-active' : ''}`} cx="150" cy="40" r="35" transform="rotate(-90, 95, 95)"></circle>
                                        </svg>
                            </figure>

                            {activeSlider === 5 &&
                                <svg
                                className="hww-carousel__panel-item__flag hww-carousel__panel-item__flag-active"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 491.325 491.325"
                                        // style="enable-background:new 0 0 491.325 491.325;"
                                        xmlSpace="preserve"
                                        >
                                    <path id="XMLID_305_" d="M429.666,22.73c-108-77.849-215.982,73.553-323.981,20.898V32.427c0-13.911-11.286-25.197-25.196-25.197
                                        c-13.91,0-25.194,11.286-25.194,25.197V466.13c0,13.909,11.284,25.195,25.194,25.195c13.91,0,25.196-11.286,25.196-25.195V282.395
                                        c102.651,50.03,205.304-84.264,307.973-30.821c4.739,2.477,10.433,2.313,15.007-0.477c4.578-2.771,7.367-7.725,7.367-13.09
                                        c0-67.612,0-135.228,0-202.859C436.031,30.245,433.65,25.6,429.666,22.73z"></path>
                                </svg>
                            }

                            {activeSlider !== 5 &&
                                <div className="hww-carousel__panel-item__top-pointer hww-carousel__panel-item__top-pointer__active"/>
                            }
                        </div>

                        <div className="hww-carousel__panel-step">
                            <div className="hww-carousel__panel-step__left hww-carousel__panel-step__right-active" />
                            <div className={`hww-carousel__panel-step__right ${activeSlider === 5 ? 'hww-carousel__panel-step__right-active' : ''}`} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
export default HowWeWork