import * as React from "react"
import FaqList from "../FaqList";

const FaqMainPage = () => {

    const sortFaq = []

    const BtnViewAllFaq = () => { return <a href="/support/faq" className="button button-primary trial-button">see all frequently asked questions</a>}

    return (
        <div className="faq-main-page">
            <div className='faq-main-page__wrapper'>
                <h2 className='faq-main-page__title'>Essay writing service FAQs</h2>
                <p className='faq-main-page__description'>The most frequently asked questions from our customers.</p>
                <FaqList>
                    <BtnViewAllFaq />
                </FaqList>
            </div>
        </div>
    )
}

export default FaqMainPage
